import React, { useRef, useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { MdTimeline } from "react-icons/md";
import { getUser } from '../utils/auth';

const Section = ({ children, id }) => {
    return (
        <section id={id} className="min-h-screen flex items-center justify-center p-8 snap-center">
            {children}
        </section>
    );
};

const BackgroundGraphic = () => (
    <svg className="fixed inset-0 w-full h-full" xmlns="http://www.w3.org/2000/svg">
        <defs>
            <pattern id="grid" width="40" height="40" patternUnits="userSpaceOnUse">
                <path d="M 40 0 L 0 0 0 40" fill="none" stroke="#2A3B4C" strokeWidth="1" />
            </pattern>
        </defs>
        <rect width="100%" height="100%" fill="url(#grid)" />
    </svg>
);

const FeatureGraphic = ({ icon }) => (
    <div className="w-16 h-16 mb-4 mx-auto bg-blue-500 bg-opacity-10 rounded-full flex items-center justify-center">
        {icon}
    </div>
);

const MinimalistDarkScrollingLanding = () => {
    const navigate = useNavigate();
    const [activeSection, setActiveSection] = useState('home');
    const containerRef = useRef(null);
    const [ShowLogin, setShowLogin] = useState(false)

    const username = getUser() || null;

    useEffect(() => {
        if (username !== null && username !== undefined && username.length > 3) {
            setShowLogin(true);
        }
    },[])
    

    const features = [
        { id: 'time-tracking', title: "Time Tracking", description: "Effortlessly log your work hours", icon: <svg className="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" /></svg> },
        { id: 'analytics', title: "Analytics", description: "Gain insights from your work data", icon: <svg className="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" /></svg> },
        { id: 'break-management', title: "Break Management", description: "Optimize your work-break balance", icon: <svg className="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" /></svg> },
        { id: 'productivity-tools', title: "Productivity Tools", description: "Boost your overall efficiency", icon: <svg className="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11 4a2 2 0 114 0v1a1 1 0 001 1h3a1 1 0 011 1v3a1 1 0 01-1 1h-1a2 2 0 100 4h1a1 1 0 011 1v3a1 1 0 01-1 1h-3a1 1 0 01-1-1v-1a2 2 0 10-4 0v1a1 1 0 01-1 1H7a1 1 0 01-1-1v-3a1 1 0 00-1-1H4a2 2 0 110-4h1a1 1 0 001-1V7a1 1 0 011-1h3a1 1 0 001-1V4z" /></svg> },
    ];

    useEffect(() => {
        const container = containerRef.current;

        const handleScroll = () => {
            if (!container) return;

            const scrollPosition = container.scrollTop + window.innerHeight / 2;
            const sections = ['home', 'benefits', 'cta'];

            for (let i = 0; i < sections.length; i++) {
                const section = document.getElementById(sections[i]);
                if (section) {
                    const sectionTop = section.offsetTop;
                    const sectionBottom = sectionTop + section.clientHeight;

                    if (scrollPosition >= sectionTop && scrollPosition < sectionBottom) {
                        setActiveSection(sections[i]);
                        break;
                    }
                }
            }
        };

        if (container) {
            container.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (container) {
                container.removeEventListener('scroll', handleScroll);
            }
        };
    }, []);

    return (
        <div ref={containerRef} className="h-screen overflow-y-scroll snap-y snap-mandatory bg-gray-900 text-gray-100 relative">
            <BackgroundGraphic />

            <Section id="home">
                {ShowLogin &&
                    (
                        <>
                            <div className='absolute h-100 w-100 text-end me-10 mt-10'>

                                <button
                                    onClick={() => navigate('/yourspace')}
                                    className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-3 rounded-lg text-lg transition duration-300"
                                >
                                    <motion.div
                                        initial={{ scale: 0 }}
                                        animate={{ rotate: 180, scale: 1 }}
                                        transition={{
                                            type: "spring",
                                            stiffness: 260,
                                            damping: 20
                                        }}
                                    >
                                        <MdTimeline />
                                    </motion.div>
                                </button>
                            </div>
                        </>
                    )
                }
                <motion.div
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 1 }}
                    className="text-center relative z-10"
                >
                    <h1 className="text-5xl md:text-7xl font-bold mb-6">Work Journal</h1>
                    <p className="text-xl md:text-2xl text-gray-400 mb-12">Streamline your productivity</p>
                    <div className="animate-bounce">
                        <p className="text-sm text-gray-500">Scroll to explore</p>
                        <svg className="w-6 h-6 mx-auto mt-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 14l-7 7m0 0l-7-7m7 7V3" /></svg>
                    </div>
                </motion.div>
            </Section>

            <Section id="benefits">
                <AnimatePresence>
                    <motion.div
                        initial={{ opacity: 0, y: 50 }}
                        animate={{ opacity: activeSection === 'benefits' ? 1 : 0.3, y: 0 }}
                        exit={{ opacity: 0, y: -50 }}
                        transition={{ duration: 0.5 }}
                        className="max-w-6xl mx-auto text-center relative z-10"
                    >
                        <h2 className="text-4xl font-bold mb-12">Key Features</h2>
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
                            {features.map((feature) => (
                                <div key={feature.id} className="bg-gray-800 bg-opacity-50 p-6 rounded-lg">
                                    <FeatureGraphic icon={feature.icon} />
                                    <h3 className="text-xl font-bold mb-2">{feature.title}</h3>
                                    <p className="text-gray-400">{feature.description}</p>
                                </div>
                            ))}
                        </div>
                    </motion.div>
                </AnimatePresence>
            </Section>

            <Section id="cta">
                <motion.div
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: activeSection === 'cta' ? 1 : 0.3, y: 0 }}
                    exit={{ opacity: 0, y: -50 }}
                    transition={{ duration: 0.5 }}
                    className="text-center relative z-10"
                >
                    <h2 className="text-4xl font-bold mb-8">Ready to optimize your workflow?</h2>
                    <button
                        onClick={() => navigate('/login')}
                        className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-3 px-8 rounded-lg text-lg transition duration-300"
                    >
                        Get Started
                    </button>
                </motion.div>
            </Section>

            <div className="fixed bottom-4 left-1/2 transform -translate-x-1/2 text-gray-500 text-sm">
                Version {process.env.REACT_APP_VERSION}
            </div>
        </div >
    );
};

export default MinimalistDarkScrollingLanding;